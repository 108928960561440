import FloatLabels from 'float-labels.js/src/float-labels';

const initFloatingLabels = () => {
  let floatingLabels = new FloatLabels('form');

  const rebuildFloatingLabels = () => {
    floatingLabels.destroy();
    floatingLabels = new FloatLabels('form');
  };

  // AJAX listeners to rebuild form.
  jQuery(document).on('gform_post_render', rebuildFloatingLabels);
};

export default initFloatingLabels;
