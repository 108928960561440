const AnimateInView = {
  init: () => {
    const config = {
      rootMargin: "-10% 0%"
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("view-active");
        } else {
          entry.target.classList.remove("view-active");
        }
      });
    }, config);

    document.querySelectorAll(".in-view").forEach(el => {
      observer.observe(el);
    });
  }
};

export default AnimateInView;
